<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <div class="title-wrapper">
          <div class="quote">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <h5 style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) text; -webkit-text-fill-color: transparent; margin: 0px;">Packages</h5>
              </div>
              <div class="col-md-6 col-sm-12">
                <a
                  :href="baseURL + 'prohibited-items'"
                  style="float: right; font-size: 16px; font-weight: 500; margin-top: 10px;"
                  class="btn"
                >
                  <i class="fa fa-list"></i> Prohibited items List
                </a>
              </div> 
            </div>
          </div>
        </div>
        <div class="col-md-12" style="background-color: #F8F8F8; padding: 10px 10px 10px 10px;margin-bottom: 40px;">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="row left">
                <p style="margin: 7px 9px 0px 20px; font-size: 15px;">Your Quote</p>
                <!-- <button class="btn btn-edit" style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 10px; line-height: 28px; padding: 2px 40px; color: #fff;" @click.prevent="showModal">Edit</button> -->
                <button
                class="btn btn-edit"
                style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 12px; line-height: 28px; padding: 2px 40px; color: #fff;"
                @click.prevent="toggleModal"
              >
                Edit
              </button>
                <!-- <router-link to="/get-a-quote" class="btn btn-edit" style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 12px; line-height: 28px; padding: 2px 40px; color: #fff;">Edit</router-link> -->
              </div>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-6 col-sm-12">
              <div class="row" style="justify-content: center;">
                <p style="margin: 7px 9px 0px 0px; font-size: 15px;">Arrange by:</p>
                <!-- <button to="" class="btn btn-edit" style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 12px; line-height: 28px; padding: 2px 25px; color: #fff; margin-right: 6px;" @click="filterByFast">Fast</button> -->
                <button
                  @click="sortByLowPrice"
                  class="btn btn-edit"
                  style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 12px; line-height: 28px; padding: 2px 25px; color: #fff; margin-right: 6px;"
                >
                  Low Price
                </button>

                <button
                  @click="sortByHighPrice"
                  class="btn btn-edit"
                  style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 12px; line-height: 28px; padding: 2px 25px; color: #fff; margin-right: 6px;"
                >
                  High Price
                </button>
                <!-- <button to="/get-a-quote" class="btn btn-edit" style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 10px; line-height: 28px; padding: 2px 25px; color: #fff; margin-right: 6px;">Best rated</button> -->
              </div>
            </div>
          </div>
        </div>
      
        <div class="delivery-services" style="display: flex; flex-wrap: wrap; gap: 20px;">
          <div class="service" v-for="service in sortedServices" :key="service.companyId" :class="{ expand: service.expand }">
            <div class="first-row">
              <div class="card" style="width: 18rem; border-radius: 15px; border: none;">
                <div class="logo" :style="{ 'background-image': 'url(' + service.companyLogoURL + ')' }"></div>
                <div class="card-body" style="background-color: #F8F8F8; border-bottom-left-radius: 14px; border-bottom-right-radius: 14px;">
                  <h1 class="card-title text-center font-weight-bold" style="font-size: 22px;">{{ service.noteTitle }}</h1>
                  <div class="card-button" style="justify-content: space-evenly; display: flex;">
                    <!-- Radio buttons for selecting insurance options -->
                    <label v-if="showInsurance" :class="{'custom-radio-label': true, 'selected': service.selectedInsurance === 'with'}">
                      <input type="radio" :name="'insurance-' + service.companyId" value="with" v-model="service.selectedInsurance" @change="handleInsuranceChange(service)">
                      With Insurance
                      <span class="tooltiptext">Tooltip text</span>
                    </label>
                    <label :class="{'custom-radio-label': true, 'selected': service.selectedInsurance === 'without'}">
                      <input type="radio" :name="'insurance-' + service.companyId" value="without" v-model="service.selectedInsurance" @change="handleInsuranceChange(service)">
                      Without Insurance
                      <span class="tooltiptext">Tooltip text</span>
                    </label>
                  </div>
                  <h1 class="card-title text-center font-weight-bold mt-4" style="font-size: 25px;">AED {{ getPrice(service) }}</h1>
                  <p class="card-text text-center delivery-time-holder" @click="showContentModal(service.noteDetail)" style="font-size: 14px;">
                    <span class="font-weight-bold" style="text-decoration: underline;
    cursor: pointer;" >Delivery Time:</span>
                    {{ extractDeliveryTime(service.noteDetail) }}
                  </p>
                 
                  <div class="actions" style="padding-left: 0px !important;">
                    <a class="btn btn-book" @click="goToBooking(service)">Book</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <!-- Insurance Details Modal  -->
          <div class="note-details-modal" v-if="showNoteModal"> <div class="close-note-modal" @click="closeNoteModal" >&Cross;</div> 
            <p v-html="noteDetail" style="margin: 5px 0px; text-align: center;
    padding: 20px;"></p>
          </div> 
        </div>
      </div>
      <!-- Modal -->
    
    
      <div v-if="isModalVisible" class="modal-overlay">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-wrapper">
              <!-- <h2>Order Details</h2> -->
              <form @submit.prevent="handleFormSubmit">
                <div class="form-row">
                  <!-- Length Field -->
                  <div class="form-group col-md-6">
                    <label for="length">Length (CM)</label>
                    <input type="text" class="form-control" id="length" v-model="formData.length" required placeholder="Enter length">
                  </div>
  
                  <!-- Width Field -->
                  <div class="form-group col-md-6">
                    <label for="width">Width (CM)</label>
                    <input type="text" class="form-control" id="width" v-model="formData.width" required placeholder="Enter width">
                  </div>
                </div>
  
                <div class="form-row">
                  <!-- Height Field -->
                  <div class="form-group col-md-6">
                    <label for="height">Height (CM)</label>
                    <input type="text" class="form-control" id="height" v-model="formData.height" required placeholder="Enter height">
                  </div>
  
                  <!-- Weight Field -->
                  <div class="form-group col-md-6">
                    <label for="weight">Weight (KG)</label>
                    <input type="text" class="form-control" id="weight" v-model="formData.weight" required placeholder="Enter weight">
                  </div>
                </div>
  
                <div class="form-row">
                  <!-- Value Field -->
                  <div class="form-group col-md-12">
                    <label for="value">Value (AED)</label>
                    <input type="text" class="form-control" id="value" v-model="formData.value" required placeholder="Enter value"  :disabled="isDisabled">
                  </div>
                </div>
  
                <div class="form-footer text-center">
                  <button type="submit" class="btn btn-primary" style="background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); font-size: 10px; line-height: 28px; padding: 2px 40px; color: rgb(255, 255, 255);">Continue</button>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';

export default {
  name: "DeliveryServices",
  components: {
    LeftMenu,
  },
  props: ["services"],
  data() {
    return {
      isDisabled: false,
      noteDetail: '',
      showNoteModal: '',
      baseURL: process.env.VUE_APP_USER_WEBSITE_URL || 'https://senditworld.com/',
      isModalVisible: false,
      formData: {
        length: '',
        width: '',
        height: '',
        weight: '',
        value: '',
      },
      sortedServices: this.services.map(service => ({
        ...service,
        selectedInsurance: 'without', // default insurance option
        additionalCost: 0, // Ensure initial value is 0
      })),
    };
  },
  mounted() {
    this.loadFormDataFromURL();
    this.getUrlParams();
  },
  methods: {
    toggleModal() {
      this.isModalVisible = !this.isModalVisible; // Toggle modal visibility
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('type');
     if (type === 'document' || type === 'passport') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    closeModal() {
      this.isModalVisible = false; // Close modal
    },
    handleModalAction() {
      this.closeModal();
    },
    handleFormSubmit() {
      console.log('Form submitted:', this.formData);
      this.updateURLAndReload();
    },
    loadFormDataFromURL() {
      // Parse URL query parameters
      const params = new URLSearchParams(window.location.search);

      // Set the formData values from URL query parameters
      this.formData.length = params.get('length') || '';
      this.formData.width = params.get('width') || '';
      this.formData.height = params.get('height') || '';
      this.formData.weight = params.get('weight') || '';
      this.formData.value = params.get('value') || '';
    },
    updateURLAndReload() {
      const params = new URLSearchParams(window.location.search);

      // Update specific parameters with the form data
      params.set('length', this.formData.length);
      params.set('width', this.formData.width);
      params.set('height', this.formData.height);
      params.set('weight', this.formData.weight);
      params.set('value', this.formData.value);

      // Construct the updated URL
      const newUrl = `${window.location.pathname}?${params.toString()}`;

      // Reload the page with the updated URL
      window.location.href = newUrl;
    },
    
    sortByLowPrice() {
      this.sortedServices = [...this.services].sort((a, b) => this.parsePrice(a.price) - this.parsePrice(b.price));
    },
    sortByHighPrice() {
      this.sortedServices = [...this.services].sort((a, b) => this.parsePrice(b.price) - this.parsePrice(a.price));
    },
    goToBooking(deliveryService) {
      deliveryService.price = this.parsePrice(deliveryService.price);
      deliveryService.additionalCost = this.parsePrice(deliveryService.additionalCost);
      // deliveryService.price = this.parsePrice(deliveryService.price) + this.parsePrice(deliveryService.additionalCost);
      
      this.$store.dispatch("setQuoteDeliveryService", deliveryService);
      this.$store.dispatch("setBookingDeliveryService", deliveryService.companyId);

      this.$router.push({ 
        path: "/booking",
        // query: { ...this.$route.query } 
       });
    },
    extractDeliveryTime(noteDetail) {
      if (!noteDetail || typeof noteDetail !== 'string') {
        // Return a fallback value or handle the case when noteDetail is null/undefined or not a string
        return 'No delivery time available';
      }

      const primaryMatch = noteDetail.match(/(\d+\s*[-to]?\s*\d*\s+(business\s+days?|business\s+day)\s+delivery\s+time)/i);
      const secondaryMatch = primaryMatch ? null : noteDetail.match(/(\d+-\d+\s\w+\s\w+\s\w+\s\w+)/);

      return primaryMatch ? primaryMatch[0] : (secondaryMatch ? secondaryMatch[0] : noteDetail);
    },
    handleInsuranceChange(service) {
      // Adjust additional cost based on insurance selection
      service.additionalCost = this.calculateAdditionalCost(service);

      // Debugging statement
      console.log(`Insurance changed for service ${service.companyId}: ${service.selectedInsurance}, Additional Cost: ${service.additionalCost}`);
    },
    // calculateAdditionalCost(selectedInsurance) {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const type = urlParams.get('type');
    //   const typeName = urlParams.get('type_name');

    //   // Check if 'type' or 'type_name' is 'document' or 'passport'
    //   if (type === 'document' || type === 'passport' || typeName === 'Document' || typeName === 'Passport') {
    //     // Return 50 if insurance is selected, otherwise 0
    //     return selectedInsurance === 'with' ? 50 : 0;
    //   }
    //   // For any other type, return 150 if insurance is selected, otherwise 0
    //   return selectedInsurance === 'with' ? 150 : 0;
    // },
    calculateAdditionalCost(service) {
      // const basePrice = this.parsePrice(service.price);
      const selectedInsurance = service.selectedInsurance;
      
      // If 'With Insurance' is selected
      if (selectedInsurance === 'with') {
        const value = Number(this.$route.query.value);
        
        // if (basePrice > 3000) {
        if (value > 3000) {
          const threshold = value * 0.05;
          const tax = threshold < 150 ? 150 : threshold;
          return tax;
          // return basePrice * 0.05;
        } else {
          // Apply a fixed cost based on the type
          const urlParams = new URLSearchParams(window.location.search);
          const type = urlParams.get('type');
          const typeName = urlParams.get('type_name');

          // Check if 'type' or 'type_name' is 'document' or 'passport'
          if (type === 'document' || type === 'passport' || typeName === 'Document' || typeName === 'Passport') {
            return 36.50;
          } else {
            return 150;
          }
        }
      }

      // No additional cost if 'Without Insurance' is selected
      return 0;
    },
    
    // getPrice(service) {
    //   const basePrice = this.parsePrice(service.price);

    //   if (isNaN(basePrice)) {
    //     console.warn(`Invalid base price for service ${service.companyId}:`, service.price);
    //     return "0.00";
    //   }

    //   const totalPrice = basePrice + (service.additionalCost || 0);
      
    //   console.log(`Calculated price for service ${service.companyId}: Base Price: ${basePrice}, Additional Cost: ${service.additionalCost}, Total: ${totalPrice}`);

    //   return totalPrice.toFixed(2);
    // },
    getPrice(service) {

      console.log("service: ",service);
      
      const basePrice = this.parsePrice(service.price);
      console.log("basePrice: ",basePrice);

      // Handle case where basePrice might be NaN
      if (isNaN(basePrice)) {
        console.warn(`Invalid base price for service ${service.companyId}:`, service.price);
        return "0.00"; // Fallback to zero price
      }

      // Calculate additional cost based on the selected insurance
      service.additionalCost = this.calculateAdditionalCost(service);

      // Calculate total price including additional cost
      const totalPrice = basePrice + service.additionalCost;

      // Debugging statement
      console.log(`Calculated price for service ${service.companyId}: Base Price: ${basePrice}, Additional Cost: ${service.additionalCost}, Total: ${totalPrice}`);

      // Return formatted price
      return totalPrice.toFixed(2);
    },
    parsePrice(price) {
      if(typeof price === 'string'){
        // Parse price to a number, handling potential issues
        const parsedPrice = parseFloat(price?.replace(/[^0-9.-]+/g, ""));
        return isNaN(parsedPrice) ? 0 : parsedPrice; // Return 0 if parsing fails
      }
      return parseFloat(price);
    },
    estimatedDelivery(service) {
      let estimatedDelivery = "";
      if (service.minDays == 0 && service.maxDays == 0) {
        estimatedDelivery = null;
      } else if (service.minDays == 0 || service.maxDays == 0) {
        estimatedDelivery = service.minDays + service.maxDays + " Days";
      } else {
        estimatedDelivery = service.minDays + " - " + service.maxDays + " Days";
      }
      return estimatedDelivery;
    },
    toggle(service) {
      if (service.noteTitle != null) {
        service.expand = !service.expand;
      }
    },
    getUrlParams() {
      // Get URL search params
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('sType');
      if (type === "domestic") {
        this.showInsurance = false; 
      } else {
        this.showInsurance = true;
      }
      this.$forceUpdate();
    },
    showContentModal(noteDetail) {
      this.noteDetail = noteDetail;
      this.showNoteModal = true; 
      console.log(noteDetail);
    },
    closeNoteModal() {
      this.showNoteModal = false; 
    }

  // watch: {
  //   formData: {
  //     handler() {
  //       this.updateURLParams();
  //     },
  //     deep: true,
  //   },
  // },
}};
</script>

<style lang="scss" scoped>
@import "../../scss/partials/variables.scss";

.service .logo {
  width: 60% !important;
  /*height: 76px !important;*/
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  margin: auto !important;
  text-align: center !important;
}

a.btn.btn-insurance-white {
  background-color: #DDDDDD;
  font-size: 14px;
  line-height: 24px;
}

.service .actions[data-v-58f6db55] {
  text-align: center;
}

.service .actions{
  text-align: center !important;
}

a.btn.btn-book {
  border-radius: 6px !important;
  box-shadow: 0 2px 20px 0 rgb(177 177 177 / 47%) !important;
  background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
  font-size: 18px;
  font-weight: 400 !important;
  border: none;
  color: #fff;
  padding: 7px 35px !important;
}

.title-wrapper {
  margin: 20px 0px;

  .title {
    text-shadow: 0 1px 48px #8986b6;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      linear-gradient(265deg, #725bba 91%, #7c4283 4%);
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.service .first-row {
  padding: 25px 0px !important;
}
.service {
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  margin-bottom: 15px;
  margin: 0 10px; // Adjust the margin to fit more cards in a row if needed
  flex: 0 0 auto; // Allow the card to adjust its width automatically
  width: 18rem;

  .first-row {
    padding: 25px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .logo {
    width: 75px;
    height: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .name {
    //flex: 1;
    padding-left: 20px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: #252423;
    flex: 0 0 30%;
  }

  .estimated-delivery {
    //flex: 1;
    padding-left: 20px;
    color: #888;
  }

  .price {
    font-size: 20px;
    font-weight: 600;
    color: #252423;
    padding-left: 30px;
    white-space: nowrap;

    span {
      font-size: 16px;
    }
  }

  .actions {
    padding-left: 30px;
    text-align: right;
    .btn {
      border-radius: 27px;
      box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
      background-color: #e79f00;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 12px 22px;
      display: block;
      display: inline-block;
      white-space: nowrap;
    }
  }

  .note-title {
    color: #252423;
    font-size: 12px;
    height: 25px;
    padding-left: 40px;
    background-image: url($srcPath + "assets/ds-arrow-down.svg");
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 25px;
    flex: 1;
    cursor: pointer;

    &.hide {
      opacity: 0;
      cursor: default;
    }
  }

  .note-detail {
    background-color: #fffbf0;
    flex: 0 0 100%;
    padding: 0px 20px;
    font-size: 13px;
    max-height: 0px;
    overflow: hidden;
    transition: all 200ms ease;

    .inner-wrapper {
      max-width: 800px;
      margin: 0px auto;
      text-align: center;
      line-height: 1.54;
    }
  }

  &.expand {
    .note-title {
      background-image: url($srcPath + "assets/ds-arrow-up.svg");
    }

    .note-detail {
      max-height: 500px;
      padding: 20px;
    }
  }
}

@media (max-width: 880px) {
  .service {
    flex-wrap: wrap;

    .logo {
      order: 1;
    }

    .name {
      //flex: 1 0 60%;
      flex: 1 0 50%;
      order: 2;
    }

    .estimated-delivery {
      flex: 1 0 60%;
      padding-left: 95px;
      order: 3;
    }

    .price {
      font-size: 20px;
      //padding-left: 95px;
      //margin-top: 10px;
      //flex: 1 0 100%;
      flex: 1;
      order: 4;
    }

    .actions {
      //flex: 1 0 100%;
      flex: 1; // 1 auto;
      margin-top: 20px;
      order: 6;
    }

    .note-title {
      order: 5;
      //margin-left: 95px;
      margin-top: 20px;
      flex: 0 0 50%;
    }
  }
}

@media (max-width: 500px) {
  .service {
    .price {
      flex: 1 0 100%;
      margin-top: 20px;
      padding-left: 0px;
    }
    .note-title {
      flex: 1;
      margin-top: 10px;
    }

    .actions {
      margin-top: 10px;
    }
  }
}

@media (max-width: 450px) {
  .service {
    margin-left: -10px;
    margin-right: -10px;

    .first-row {
      padding: 25px 15px;
    }

    .note-title {
      //margin-left: 0px;
      padding-left: 30px;
    }

    .actions {
      //margin-top: 30px;
      padding-left: 0px;
    }
  }
}

@media (max-width: 1200px) {
  .service .first-row {
    flex-wrap: wrap; // Allow wrapping on smaller screens
  }
  .service {
    width: 100%; // Full width on smaller screens if wrapping
  }
}

@media (max-width: 768px) {
  .service .first-row {
    flex-direction: column; // Stack vertically on very small screens
  }
}

.custom-radio-label {
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  transition: all 0.3s ease;
}

.custom-radio-label input[type="radio"] {
  display: none;
}

.custom-radio-label.selected {
  background-color: #005f73; /* Change this color to match the desired style */
  color: #fff;
  border: none;
}

.custom-radio-label:not(.selected) {
  background-color: #DDDDDD; 
  color: #333;
}

.custom-radio-label:hover {
  border-color: #005f73; /* Optional: Highlight border on hover */
}

/**/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.close {
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  background: none;
}

/*.modal-body {
  text-align: center;
} */

.modal-footer {
  margin-top: 20px;
}
/**/

.service .btn-book:hover {
  background-image: linear-gradient(to right, #2c2a2a 0%, #1985A1 100%);
}

// tooltip 

.custom-radio-label {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.custom-radio-label .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-radio-label .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

// time Popoup 
.note-details-modal{
    position: absolute;
    background-color: white;
    padding: 20px;
    box-shadow: #818181 -1px 3px 15px;
    border-radius: 5px;
}
.close-note-modal{
  font-size: 25px; 
  font-weight: bold; 
  text-align: end; 
  cursor: pointer;
}
// .custom-radio-label:hover .tooltiptext {
//   visibility: visible;
//   opacity: 1;
// }
</style>
