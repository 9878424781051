<template>
  <h5
    style="
      font-size: 36px;
      font-weight: bold;
      background: linear-gradient(
          100deg,
          rgb(25, 133, 161) 0%,
          rgb(2, 68, 85) 100%
        )
        text;
      -webkit-text-fill-color: transparent;
      margin: 0px;
    "
  >
    Get a Quote
  </h5>

  <div class="quote-n-book-widget-container"></div>
  <div class="quote-contact-form" ref="quoteForm">
    <div class="form-outer-wrapper form-style-1" :class="formState">
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.observeElement();
  },
  methods: {
    observeElement() {
      const target = this.$refs.quoteForm;
      if (target) {
        if (window.qnbScriptAdded) {
          location.reload();
        }
        if (!window.qnbScriptAdded) {
          window.qnbScriptAdded = true;
          window.qnbwe = this.expanded;
          window.userApp = true;

          // Remove any existing script with the same src
          const existingScript = document.querySelector(
            `script[src*="widget_new.js.php"]`
          );
          if (existingScript) {
            document.head.removeChild(existingScript);
          }

          var j = document.createElement("script");
          j.src =
            process.env.VUE_APP_MARKETING_CDN_SITE_URL +
            "quote-n-book/widget_new.js.php?ver=" +
            Date.now();
          j.async = true;
          document.head.appendChild(j);
        }
      }
    },
  },
};
</script>


    <style>
@media (max-width: 1233px) {
  .locations .from-field-wrapper {
    min-width: 160px !important;
  }

  .to-field-wrapper.international {
    min-width: 160px !important;
  }
}

@media (max-width: 1071px) {
  .locations {
    display: flex;
    flex-direction: column;
  }

  .quote-n-book-widget .locations {
    max-width: 100%;
  }

  .quote-n-book-widget .length-field-wrapper {
    width: unset;
  }

  .locations .ml-3 {
    margin-left: 0px !important;
  }
}

@media (max-width: 768px) {
  .quote-item-details .quote-image {
    height: 100px;
  }

  #quote-form {
    border-radius: 0px !important;
  }

  .quote-n-book-widget .buttons.mt-4.mb-2 {
    flex-wrap: wrap-reverse !important;
  }
}

@media (max-width: 500px) {
  .buttons a.more-info {
    font-size: 14px;
    padding: 10px 10px;
  }

  .main-cols .right-content-col {
    padding-left: 0px;
  }

  .quote-n-book-widget .width-field-wrapper {
    padding-right: 0px !important;
  }

  .quote-n-book-widget .length-field-wrapper.ml-3 {
    padding-right: 0px !important;
  }

  .locations .fields {
    flex-direction: column;
  }

  .locations .w-v-fields {
    flex-direction: column !important;
  }

  .locations .w-v-fields .value-field-wrapper {
    width: 100% !important;
  }

  .locations .w-v-fields input[type="submit"] {
    margin-left: 0px !important;
  }
}
</style>